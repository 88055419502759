import request from "../plugins/axios";

// 答卷列表
export function naireResultIndex(params) {
    return request({
        url: '/naire/resultIndex',
        method: 'GET',
        params
    })
}

// 答卷列表
export function naireResultDetail(params) {
    return request({
        url: '/naire/resultDetail',
        method: 'GET',
        params
    })
}

// 评分排行
export function naireScoreIndex(params) {
    return request({
        url: '/naire/scoreIndex',
        method: 'GET',
        params
    })
}
<template>
  <div class="index">
    <div class="content">

      <div class="screen">
        <van-button plain hairline size="small" @click="$refs.department.$show([])" icon="https://img01.yzcdn.cn/vant/user-active.png" type="info">
          {{ setDepartment[0] ? setDepartment[0].name : '按部门查看' }}
        </van-button>
      </div>

      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <img src="../../assets/score_1.png" alt="" v-if="index == 0">
          <img src="../../assets/score_2.png" alt="" v-else-if="index == 1">
          <img src="../../assets/score_3.png" alt="" v-else-if="index == 2"> 
          <div class="name">
            {{ item.employee.name }} <van-tag plain type="primary">{{ item.department.name }}</van-tag>
          </div>
          <div>
            平均{{ item.average_score }}分
          </div>
        </div>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('../../assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
    <departmentSelect ref="department" @change="departmentChange"/>
  </div>
</template>
<script>
import { naireScoreIndex } from '@/api/naire'
import departmentSelect from "@/components/departmentSelect"
export default {
  components: {
    departmentSelect
  },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15,
      setDepartment: []
    } 
  },
  mounted () {
    this.onLoad()
  },
  methods: {
    departmentChange (v) {
      this.setDepartment = v
      this.page = 1
      this.list = []
      this.onLoad()
    },
    setDate () {
      let startDay = this.dateFormatStr(this.currentDate.startDay)
      let endDay = this.dateFormatStr(this.currentDate.endDay)
      this.onLoad(startDay, endDay)
      this.dateStr.startDay = startDay
      this.dateStr.endDay = endDay
      this.setTimeCheck = true
      this.showSelectDay = false
    },
    onLoad () {
      this.loading = true
      let param = {
        page: this.page,
        page_size: this.perPage
      }
      if (this.setDepartment.length && this.setDepartment[0]) {
        param.department_id = this.setDepartment[0].id
      }
      naireScoreIndex(param).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.showRight = false
        this.list = this.list.concat(res.data.lists.data || [])
      }, res => {
        this.loading = false
      })
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/.van-action-sheet {
    max-height: 98%;
  }

  .content {
    // background: #f4f5f7;
    padding: 16px 12px;

    .screen {
      margin-bottom: 16px;
    }

    .switch {
      overflow: hidden;
      margin-bottom: 12px;

      .ant-select {
        float: right;
      }
    }

    .loading {
      padding: 20%;
      text-align: center;
    }

    .list-item {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 12px;
      border-radius: 8px;
      margin-bottom: 12px;

      .name {
        font-size: 16px;
      }

      img {
        width: 30px;
        margin-right: 6px;
      }

      >div:last-child {
        flex: 1;
        text-align: right;
        padding-right: 10px;

        /deep/.van-icon {
          position: relative;
          top: 2px;
        }
      }
    }
  }

  .van-picker {
    margin-bottom: 20px;
  }

  .showSelectDayBtn {
    padding: 10px 5px;
    display: flex;

    button {
      flex: 1;
      margin:  0 5px;
    }
  }
}
</style>
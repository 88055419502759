<template>
  <div class="index">
    <div class="content">
      <div class="switch">
        <a-select style="min-width: 80px" size="small" :value="checkType" @change="changeCheckType">
          <a-select-option :key="1">今天</a-select-option>
          <a-select-option :key="2">昨天</a-select-option>
          <a-select-option :key="3">本月</a-select-option>
          <a-select-option :key="4" @click="showSelectDay = true;">{{ setTimeCheck ? dateStr.startDay + '至' + dateStr.endDay : '自定义' }}</a-select-option>
        </a-select>
      </div>
      <div class="list-item" v-for="(item, index) in list" :key="index">
        <a-avatar class="avatar" shape="square" :size="45" icon="user" :src="item.avatar" />
        <div>
          {{ item.name }}
        </div>
        <div>
          <van-icon name="friends" size="20px" color="#1989fa"/> 拉新{{ item.total }}人
        </div>
      </div>
      <div class="loading" v-if="loading">
        <a-spin/>
      </div>
      <van-empty
        v-if="!loading && !list.length"
        description="暂无数据"
      />
    </div>
    <!-- 自定义时间 -->
    <van-action-sheet v-model="showSelectDay" :close-on-click-overlay="false">
      <van-datetime-picker
        v-model="currentDate.startDay"
        type="date"
        title="开始日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <van-datetime-picker
        v-model="currentDate.endDay"
        type="date"
        title="结束日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <div class="showSelectDayBtn">
        <van-button @click="clearSetectDay">取消</van-button>
        <van-button type="info" @click="setDate">确认</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { employeeRanking } from '@/api/pullNew'
export default {
  data() {
    return {
      list: [],
      dateStr: {},
      checkType: 1,
      showSelectDay: false,
      setTimeCheck: false,
      loading: false,
      currentDate: {
        startDay: new Date(),
        endDay: new Date()
      }
    } 
  },
  mounted () {
    this.onLoad()

  },
  methods: {
    clearSetectDay () {
      this.showSelectDay = false
    },
    changeCheckType (v) {
      this.checkType = v
      this.setTimeCheck = false
      switch (v) {
        case 1:
          this.onLoad()
          break
        case 2:
          this.onLoad(this.dateFormat(new Date() - (86400 * 1 * 1000)), this.dateFormat(new Date() - (86400 * 1 * 1000)))
          break
        case 3:
          const date = new Date()
          const year = date.getFullYear()
          const month = date.getMonth()
          this.onLoad(year + '-' + (month+1) + '-01', this.dateFormat(new Date()))
          break
      }
    },
    setDate () {
      let startDay = this.dateFormatStr(this.currentDate.startDay)
      let endDay = this.dateFormatStr(this.currentDate.endDay)
      this.onLoad(startDay, endDay)
      this.dateStr.startDay = startDay
      this.dateStr.endDay = endDay
      this.setTimeCheck = true
      this.showSelectDay = false
    },
    onLoad (startDay, endDay) {
      this.loading = true
      this.list = []
      employeeRanking({
        day: startDay || this.dateFormat(new Date()),
        endDay: endDay || this.dateFormat(new Date())
      }).then(res => {
        this.list = res.data
        this.loading = false
      }, res => {
        this.loading = false
      })
    },
    dateFormat(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      return Y + M + D
    },
    dateFormatStr (time) {
      var Y = time.getFullYear() + '-';
      var M = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-';
      var D = (time.getDate() < 10 ? '0' + time.getDate() : time.getDate());
      return Y + M + D
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/.van-action-sheet {
    max-height: 98%;
  }

  .content {
    // background: #f4f5f7;
    padding: 16px 12px;

    .switch {
      overflow: hidden;
      margin-bottom: 12px;

      .ant-select {
        float: right;
      }
    }

    .loading {
      padding: 20%;
      text-align: center;
    }

    .list-item {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 12px;
      border-radius: 8px;
      margin-bottom: 12px;

      .avatar {
        margin-right: 6px;
      }

      >div:last-child {
        flex: 1;
        text-align: right;
        padding-right: 10px;

        /deep/.van-icon {
          position: relative;
          top: 2px;
        }
      }
    }
  }

  .van-picker {
    margin-bottom: 20px;
  }

  .showSelectDayBtn {
    padding: 10px 5px;
    display: flex;

    button {
      flex: 1;
      margin:  0 5px;
    }
  }
}
</style>
<template>
  <div class="index">
    <div class="content" style="overflow:hidden;">
      <van-tabs v-model="active"  color="#1890ff" title-active-color="#1890ff">
        
        <van-tab name="1" title="拉新排行">
          <template #title><van-icon name="friends-o" /> 拉新排行</template>
          <lachine></lachine>
        </van-tab>

        <van-tab name="2" title="评分排行">
          <template #title><van-icon name="chart-trending-o" /> 评分排行</template>
          <score></score>
        </van-tab>
        
      </van-tabs>
    </div>
  </div>
</template>

<script>
import lachine from '../employeeRanking/lachine'
import score from '../employeeRanking/score'
export default {
  components: {
    lachine,
    score
  },
  data() {
    return {
      active: '1',
    }
  },
  
  created () {
  },
  methods: {
 
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 9px;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 8px;
}

/deep/ .van-tabs__wrap {
  height: 54px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
  padding-top: 10px;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/.van-action-sheet {
  max-height: 98%;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  p {
    margin: 0 ;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    // background: #fff;
    min-height: 92%;
    // padding-top: 5px;

  }
}
</style>
